















































































































































































































































































































































































































































































































































































































































@import "../assets/scss/_multiselect.scss";
.nav-editor{
  .btn{
    padding: 6px 12px;
    color: #5e4bf1;
    font-size: 14px;
    font-weight: 500;
    transition: all .24s ease-out;
    cursor: pointer;
    &:first-child{
      padding-left: 0!important;
    }
  }
}
.nav-editor .btn:hover{
  opacity: 0.8;
}
.editor-wrapper{
  display: block;
  margin-bottom: 8px;
  border: 1px solid rgba(0,0,0,.08);
  border-radius: 6px;
  padding: 10px 12px;
  .ProseMirror {
    font-size: 14px;
    min-height: 50px;
    outline: none;
    color: #161616;
    border-radius: 6px;
    > * + * {
      margin-top: 15px;
    }
    ul{
      list-style: disc;
    }
    ol{
      list-style: decimal;
    }
    ul,
    ol {
      padding: 0 1rem;
    }
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    content: 'Requirements';
    float: left;
    font-size: 14px;
    color: #767676;
    pointer-events: none;
    height: 0;
  }
}
.image-upload{
  position: relative;
  padding-bottom: 36%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  max-width: 320px;
  overflow: hidden;
  &-inner{
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      img{
        max-width: 100%;
      }
    }
  }
  .up-img{
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.drop-block{
  transition: all .34s ease-out;
  &.is-hover{
    background-color: rgba(0, 0, 0, 0.12);
  }
}
