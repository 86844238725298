/*==========  Select Style  ==========*/
div.ml-select-wrapper-material {
  position: relative;
  > label{
    position: absolute;
    top: 15px;
    left: 12px;
    color: #767676;
    font-size: 14px;
    line-height: 1.2em;
    transition: 0.32s;
    z-index: 0;
    cursor: pointer;
  }
  .multiselect__select{
    display: none;
  }
  .multiselect__tags{
    min-height: 47px;
    padding: 10px 12px 0 12px;
    border: none;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px!important;
    transition: all .34s ease-out;
  }
  .multiselect__tag{
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 6px 28px 6px 8px;
    color: #000000;
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
    &-icon{
      font-weight: 500;
      transition: all .34s ease-out;
      &:after{
        font-size: 20px;
        color: #323232;
      }
    }
  }
  .multiselect__input,
  .multiselect__single{
    background: rgba(0, 0, 0, 0);
    padding: 0!important;
  }
  &:not(.typed){
    .multiselect__input{
      margin-bottom: 0;
      margin-top: 12px;
      font-size: 14px;
    }
  }
  &.typed{
    > label{
      top: 7px;
      font-size: 12px;
    }
    .multiselect__tags{
      padding-top: 26px;
    }
  }
  .multiselect.multiselect--active{
    + label{
      top: 7px;
      font-size: 12px;
    }
  }
  .multiselect__content-wrapper{
    border-radius: 16px;
    padding: 14px 8px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    border: none;
    top: calc(100% + 10px);
    overflow: hidden;
    height: max-content;
  }
  .multiselect__content{
    max-height: calc(300px - 30px);
    padding: 0 6px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
  }
  .multiselect__element{
    margin-bottom: 2px;
  }
  .multiselect__option{
    padding: 8px 10px;
    min-height: 28px;
    line-height: 1.2em;
    font-size: 14px;
    border-radius: 8px;
    color: #161616;
    transition: all .34s ease-out;
  }
  .multiselect__option--highlight,
  .multiselect__option--selected{
    background-color: rgba(0, 0, 0, 0.08);
    color: #161616;
    font-weight: 400;
  }
  .multiselect__option--selected:after,
  .multiselect__option--highlight:after{
    display: none!important;
  }
  .multiselect.multiselect--active {
    .multiselect__tags{
      box-shadow: 0px 0px 0px 2px #5e4bf1;
      background: rgba(0, 0, 0, 0.04);
    }
  }
  &.z-index-1{
    .multiselect{
      z-index: 1;
    }
    > label{
      z-index: -1;
    }
  }
}
div.sl-select-wrapper-material{
  position: relative;
  > label{
    position: absolute;
    top: 16px;
    left: 12px;
    color: #767676;
    font-size: 14px;
    line-height: 1.2em;
    transition: 0.32s;
    z-index: 0;
    cursor: pointer;
  }
  .multiselect__tags{
    min-height: 48px;
    padding: 14px 40px 0 12px;
    border: none;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 6px!important;
    transition: all .34s ease-out;
  }
  .multiselect__content-wrapper{
    border-radius: 16px;
    padding: 14px 8px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    border: none;
    top: calc(100% + 10px);
    overflow: hidden;
    height: max-content;
  }
  .multiselect__content{
    max-height: calc(300px - 30px);
    padding: 0 6px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
  }
  .multiselect__single {
    padding: 0;
    margin: 0;
    background: rgba(1,1,1,0);
    font-size: 14px;
    color: #161616;
  }
  .multiselect__select {
    width: 40px;
    height: 100%;
    &:before{
      top: 60%;
      border-color: #161616 transparent transparent;
    }
  }
  .multiselect__element{
    margin-bottom: 2px;
  }
  .multiselect__option{
    padding: 8px 10px;
    min-height: 28px;
    line-height: 1.2em;
    font-size: 14px;
    border-radius: 8px;
    color: #161616;
    transition: all .34s ease-out;
  }
  .multiselect__option--highlight,
  .multiselect__option--selected{
    background-color: rgba(0, 0, 0, 0.08);
    color: #161616;
    font-weight: 400;
  }
  &.typed{
    > label{
      top: 8px;
      font-size: 12px;
    }
    .multiselect__tags{
      padding-top: 22px;
    }
  }
  .multiselect.multiselect--active {
    .multiselect__tags{
      box-shadow: 0px 0px 0px 2px #5e4bf1;
      background: rgba(0, 0, 0, 0.04);
    }
    + label{
      top: 7px;
      font-size: 12px;
    }
  }
  &.search{
    .multiselect__input{
      background-color: rgba(1, 1, 1, 0);
      padding-left: 0!important;
      margin-top: 6px;
    }
  }
  &.z-index-1{
    .multiselect{
      z-index: 1;
    }
    > label{
      z-index: -1;
    }
  }
}

/*==========  Hover  ==========*/

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover{
  background-color: rgba(1,1,1,0);
  opacity: 0.8;
  &:after{
    color: #323232;
  }
}

@media (min-width: 1200px){
  div.sl-select-wrapper-material:hover .multiselect__tags {
    background-color: rgba(0, 0, 0, 0.08);
  }
  div.ml-select-wrapper-material:hover .multiselect__tags {
    background-color: rgba(0, 0, 0, 0.08);
  }
  div.ml-select-wrapper-material .multiselect__tag-icon:focus,
  div.ml-select-wrapper-material .multiselect__tag-icon:hover {
    background-color: rgba(1,1,1,.1);
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)



