






















































































































































































































































































































































































































.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
  }
}
